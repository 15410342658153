
import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

@Component
export default class MetadataDisplay extends Vue {
  @Prop({ required: true }) metadata!: Record<string, any>;

  get metadataEntries() {
    return Object.entries(this.metadata);
  }

  get firstHalfMetadata() {
    return this.metadataEntries.slice(
      0,
      Math.ceil(this.metadataEntries.length / 2)
    );
  }

  get secondHalfMetadata() {
    return this.metadataEntries.slice(
      Math.ceil(this.metadataEntries.length / 2),
      this.metadataEntries.length
    );
  }

  public dateParse(ts) {
    if (typeof ts !== 'string' || !ts) {
      return ts;
    }
    // Check if ts matches date format before parsing
    if (!ts.match(/^\d{4}-\d{2}-\d{2}/) && !ts.match(/^\d{4}-\d{2}-\d{2}T/)) {
      return ts;
    }
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    try {
      return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY HH:mm");
    } catch (e) {
      return ts;
    }
  }
}
